import { createIcon } from '@chakra-ui/react'

export const DashboardIcon = createIcon({
  displayName: 'CircularGraphIcon',
  path: (
    <g transform='translate(-20.000000, -20.000000)' fill='currentColor' fillRule='nonzero'>
      <g id='circular-graph' transform='translate(20.000000, 20.000000)'>
        <path
          d='M52.752,28.116 L58.752,28.116 C58.557,24.969 57.873,21.957 56.748,19.164 L51.48,22.206 C52.152,24.075 52.593,26.055 52.752,28.116 Z M42.303,3.885 C39.438,2.532 36.333,1.614 33.045,1.275 L33.045,7.338 C35.244,7.629 37.344,8.223 39.297,9.093 L42.303,3.885 Z M48.726,16.866 L53.949,13.851 C52.176,11.217 49.959,8.916 47.433,6.996 L44.415,12.222 C46.062,13.56 47.508,15.126 48.726,16.866 Z M30.042,52.713 C17.451,52.713 7.242,42.507 7.242,29.913 C7.242,18.339 15.873,8.805 27.045,7.335 L27.045,1.272 C12.546,2.772 1.242,15.021 1.242,29.913 C1.242,45.822 14.136,58.716 30.045,58.716 C44.517,58.716 56.466,48.027 58.503,34.116 L52.44,34.116 C50.466,44.697 41.196,52.713 30.042,52.713 Z'
          id='Shape'
        ></path>
      </g>
    </g>
  ),
  viewBox: '0 0 60 60',
})
